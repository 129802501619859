import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({

  footer: {
      // marginTop: theme.spacing(10),
      height: 140,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main
  },
  footerText: {
      color: 'white',
      paddingTop: theme.spacing(0.5),
  },
  footerTextLinks: {
      color: 'white',
      paddingTop: theme.spacing(0.5),
      textDecoration: 'none',
      "&:hover": {
          textDecoration: 'underline'
      }
  },
  footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
  },
  links: {
      color: '#ccc'
  },
  contacts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
  },
});

class Footer extends React.Component {
    render(){
        const { classes } = this.props;
        return(
            <div className={classes.footer}>
                <Container className={classes.footerContainer}>
                    <Typography variant="subtitle1" className={classes.footerText}>
                        © 2021 ThreadsAPI. All Rights Reserved.
                    </Typography>
                    <div className={classes.contacts}>
                        <Typography variant="subtitle1" className={classes.links}>
                            Links
                        </Typography>
                        <a
                            target={'_blank'}
                            rel="noopener noreferrer"
                            className={classes.footerTextLinks}
                            href='https://twitter.com/_JonathanBarone'>
                          {"Twitter"}
                        </a>
                        <a
                            target={'_blank'}
                            rel="noopener noreferrer"
                            className={classes.footerTextLinks}
                            href='mailto:johnito.barone@gmail.com'>
                          {"Contact"}
                        </a>
                        <a
                            target={'_blank'}
                            rel="noopener noreferrer"
                            className={classes.footerTextLinks}
                            href='https://www.buymeacoffee.com/jonathanbarone'>
                          {"Buy Me A Coffee"}
                        </a>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Footer);
