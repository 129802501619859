import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ff5757',
    },
    secondary: {
      main: '#ff3f2e',
    },
    error: {
      main: red.A400,
    },
    bar: {
      main: '#111111',
    },
    background: {
        default: '#fbf4f4',
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme.typography.h2 = {
  fontSize: '1.2rem',
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4.6rem',
  },
};

theme.typography.h3 = {
  fontSize: '1.4rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

export default theme;
