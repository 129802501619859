import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import StarIcon from '@material-ui/icons/StarBorder';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import hero from "./assets/hero_image.png";
import step1 from "./assets/threadsapi_step1.png";
import step2 from "./assets/threadsapi_step2.png";
import step3 from "./assets/threadsapi_step3.png";
import airtable from "./assets/twitter_to_airtable.png";
import { MixPanel } from './components/MixPanel';


const styles = theme => ({
  app: {
    backgroundColor: theme.palette.background.default,
  },
  intro: {
      paddingBottom: theme.spacing(10),
  },
  features: {
      // backgroundColor: '#c51a2e',
      background: 'linear-gradient(to right, rgb(197, 26, 46), rgb(255, 63, 46))',
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(2),
  },
  howitworks: {
      backgroundColor: '#ff8b84',
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(2),
  },
  tutorials: {
      backgroundColor: '#f4cccc',
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(2),
  },
  pricing: {
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(2),
  },
  cardMediaStyle: {
    maxWidth: 960,
    // opacity: 0.5
  },
  howitworksCardMediaStyle: {
    [theme.breakpoints.up('md')]: {
        width: 460,
        height: 301,
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: 300,
    },
    // opacity: 0.5
  },
  howitworksCardMediaStyle2: {
    [theme.breakpoints.up('md')]: {
        width: 460,
        height: 301,
        marginTop: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: 300,
    },
    // opacity: 0.5
  },
  container: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
  },
  featuresContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
  },
  title: {
      marginTop: theme.spacing(8)
  },
  button: {
      marginTop: theme.spacing(8),
      backgroundColor: '#FFB92E',
      color: 'black',
      minWidth: '420px',
      minHeight: '80px',
      [theme.breakpoints.down('sm')]: {
          minWidth: '360px',
          minHeight: '70px',
      },
      fontSize: 'large',
      "&:hover": {
          backgroundColor: '#FFD37A'
      },
  },

  whiteText: {
      color: 'white'
  },
  featureDiv: {
      display: 'flex',
      marginTop: theme.spacing(2),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(4),
      },
  },
  placeholderDiv: {
      flex:1,
      [theme.breakpoints.down('sm')]: {
          flex:0
      },
      display: '50%'
  },
  featureText: {
      flex:1,
      display: '50%'
  },
  whiteBoldItalicText:{
      color: 'white',
      fontWeight: 'bold',
      fontStyle: 'italic',
  },
  boldText: {
      fontWeight: 'bold',
  },
  howitworksMasterDiv: {
      display: 'flex',
      marginTop: theme.spacing(2),
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
      },
      justifyContent: 'center',
      alignItems: 'center',
  },
  howitworksDiv: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(4),
          marginRight: 0
      },
      marginLeft: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
  },
  cardHeader: {
    backgroundColor:
      theme.palette.primary.main,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardContent: {
      height: 240
  },
  card: {
      width: 340
  },
  gridPricing: {
      display: 'flex',
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
  },
  pricingCards: {
      marginTop: theme.spacing(4)
  },
  tutoRoot: {
    maxWidth: 345,
  },
  tutoMedia: {
    height: 140,
  },
});

const tiers = [
  {
    title: 'Basic',
    price: '0',
    description: [
        '300 requests/month',
        'https encryption',
        'email support'],
    buttonText: 'Get Started for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '6',
    description: [
      '50,000 requests/month',
      'checkpointing',
      'https encryption',
      'email support'
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Mega',
    price: '29',
    description: [
      '400,000 requests/month',
      'checkpointing',
      'query caching',
      'custom integration',
      'https encryption',
      'technical support'
    ],
    buttonText: 'Contact me',
    buttonVariant: 'outlined',
  },
];

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }
    componentDidMount(){
        MixPanel.track('View Landing Page')
    }
    openNotionDoc = () => {
        MixPanel.track('Click Notion Tutorial')
        window.open("https://www.notion.so/Get-Twitter-threads-to-Airtable-8840ffa10f6e4e45b13f37a5a6bc5bde", "_blank");
    }
    goToRapidAPIPricing = (plan=null) => {
        MixPanel.track('Click RapidAPI', {plan: plan, location: "pricing"})
        window.open("https://rapidapi.com/chaos87/api/threads/", "_self");
    }
    goToRapidAPI = () => {
        MixPanel.track('Click RapidAPI', {location: "hero"})
        window.open("https://rapidapi.com/chaos87/api/threads/", "_self");
    }
    contactMe = () => {
        MixPanel.track('Click Contact Me')
        window.open("mailto:johnito.barone@gmail.com", "_self");

    }
    render() {
      const { classes } = this.props;
      return (
        <div className={classes.app}>
            <div className={classes.intro}>
              <Header />
              <Container maxWidth="md" className={classes.container}>
                  <CardMedia src={hero} component="img" alt='screenshot of search threads app' className={classes.cardMediaStyle}/>
                  <Typography variant='h2' color="secondary" align='center' className={classes.title}>
                      Extract Twitter threads effortlessly
                  </Typography>
                  <Typography paragraph variant='h3' align='center' className={classes.title}>
                      Because curating threads manually is painful
                  </Typography>
                  <Typography paragraph variant='h3' align='center'>
                    Save <mark style={{backgroundColor: "#ff3f2e"}}>time and money</mark> by using this low-code API endpoint
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={this.goToRapidAPI}
                  >
                    Get Started - It's free
                  </Button>
              </Container>
            </div>
            <div className={classes.howitworks}>
                <Container maxWidth="md" className={classes.featuresContainer}>
                    <Typography paragraph variant='h4' align='center'>
                        How does it work?
                    </Typography>
                    <div className={classes.howitworksMasterDiv}>
                        <div className={classes.howitworksDiv}>
                            <CardMedia src={step1} component="img" alt='screenshot of search threads app' className={classes.howitworksCardMediaStyle}/>
                            <Typography variant='body2' align='left' className={classes.boldText}>
                                1 - Create your RapidAPI account
                            </Typography>
                        </div>
                        <div className={classes.howitworksDiv}>
                            <CardMedia src={step2} component="img" alt='screenshot of search threads app' className={classes.howitworksCardMediaStyle2}/>
                            <Typography variant='body2' align='left' className={classes.boldText}>
                                2 - Provide your tokens and make a request to /setup from RapidAPI UI.
                                Your tokens are safely encrypted and stored in database
                            </Typography>
                        </div>
                        <div className={classes.howitworksDiv}>
                            <CardMedia src={step3} component="img" alt='screenshot of search threads app' className={classes.howitworksCardMediaStyle}/>
                            <Typography variant='body2' align='left' className={classes.boldText}>
                                3 - Call the threads endpoint from your browser or a nocode tool
                            </Typography>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.features}>
                <Container maxWidth="md" className={classes.featuresContainer}>
                    <Typography paragraph variant='h4' align='center' className={classes.whiteText}>
                        Features
                    </Typography>
                    <div className={classes.featureDiv}>
                        <div className={classes.featureText}>
                            <Typography variant='h6' align='left' className={classes.whiteText}>
                                <span role="img" aria-label="emoji down">🧵</span> Search threads by users
                            </Typography>
                            <Typography paragraph variant='body1' align='left' className={classes.whiteText}>
                                Call the /threads/users endpoint to fetch latest threads from a specific Twitter user
                                You can filter by date or pass the tweet id to start fetching from
                            </Typography>
                            <Typography align='center' variant="body2" className={classes.whiteBoldItalicText}>
                               Now live!
                            </Typography>
                        </div>
                        <div className={classes.placeholderDiv}>

                        </div>
                    </div>
                    <div className={classes.featureDiv}>
                        <div className={classes.placeholderDiv}>

                        </div>
                        <div className={classes.featureText}>
                            <Typography variant='h6' align='left' className={classes.whiteText}>
                                <span role="img" aria-label="emoji down">🧵</span> Search threads by keyword
                            </Typography>
                            <Typography paragraph variant='body1' align='left' className={classes.whiteText}>
                                Call the /threads/search endpoint to fetch latest threads (past 7 days) using Twitter advanced search functionality
                            </Typography>
                            <Typography align='center' variant="body2" className={classes.whiteBoldItalicText}>
                               Coming Soon
                            </Typography>
                        </div>

                    </div>
                    <div className={classes.featureDiv}>
                        <div className={classes.featureText}>
                            <Typography variant='h6' align='left' className={classes.whiteText}>
                                <span role="img" aria-label="emoji down">🧵</span> Checkpointing
                            </Typography>
                            <Typography paragraph variant='body1' align='left' className={classes.whiteText}>
                                Saves the latest thread fetched for a user on the server so that each call to /threads/user for the same user only returns the new threads
                            </Typography>
                            <Typography align='center' variant="body2" className={classes.whiteBoldItalicText}>
                               Now live!
                            </Typography>
                        </div>
                        <div className={classes.placeholderDiv}>

                        </div>
                    </div>
                    <div className={classes.featureDiv}>
                        <div className={classes.placeholderDiv}>

                        </div>
                        <div className={classes.featureText}>
                            <Typography variant='h6' align='left' className={classes.whiteText}>
                                <span role="img" aria-label="emoji down">🧵</span> Store results in Airtable
                            </Typography>
                            <Typography paragraph variant='body1' align='left' className={classes.whiteText}>
                                Export the results of either /threads/user or /threads/search endpoints to a table in Airtable
                            </Typography>
                            <Typography align='center' variant="body2" className={classes.whiteBoldItalicText}>
                               Now live!
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.featureDiv}>
                        <div className={classes.featureText}>
                            <Typography variant='h6' align='left' className={classes.whiteText}>
                                <span role="img" aria-label="emoji down">🧵</span> Store results in Notion
                            </Typography>
                            <Typography paragraph variant='body1' align='left' className={classes.whiteText}>
                                Export the results of either /threads/user or /threads/search endpoints to a table in Notion
                            </Typography>
                            <Typography align='center' variant="body2" className={classes.whiteBoldItalicText}>
                               Coming Soon
                            </Typography>
                        </div>
                        <div className={classes.placeholderDiv}>

                        </div>
                    </div>
                </Container>
            </div>
            <div className={classes.pricing}>
                <Container maxWidth="lg" className={classes.container}>
                    <Typography paragraph color="secondary" variant='h4' align='center' className={classes.boldText}>
                        Pricing
                    </Typography>
                    <Grid container spacing={5} justify="center" alignItems="flex-end" className={classes.pricingCards}>
                      {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} sm={6} md={3} className={classes.gridPricing}>
                          <Card className={classes.card}>
                            <CardHeader
                              title={tier.title}
                              subheader={tier.subheader}
                              titleTypographyProps={{ align: 'center' }}
                              subheaderTypographyProps={{ align: 'center' }}
                              action={tier.title === 'Pro' ? <StarIcon /> : null}
                              className={classes.cardHeader}
                            />
                            <CardContent className={classes.cardContent}>
                              <div className={classes.cardPricing}>
                                <Typography component="h2" variant="h3" color="textPrimary">
                                  ${tier.price}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                  /mo
                                </Typography>
                              </div>
                              <ul>
                                {tier.description.map((line) => (
                                  <Typography component="li" variant="subtitle1" align="center" key={line}>
                                    {line}
                                  </Typography>
                                ))}
                              </ul>
                            </CardContent>
                            <CardActions>
                            {tier.buttonText === 'Contact me' ?
                              <Button fullWidth variant={tier.buttonVariant} color="secondary" onClick={this.contactMe}>
                                {tier.buttonText}
                              </Button>
                              : <Button fullWidth variant={tier.buttonVariant} color="secondary" onClick={() => this.goToRapidAPIPricing(tier.title)}>
                                {tier.buttonText}
                              </Button>
                             }
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                </Container>
            </div>
            <div className={classes.tutorials}>
                <Container maxWidth="md" className={classes.featuresContainer}>
                    <Typography paragraph variant='h4' align='center'>
                        Tutorials
                    </Typography>
                    <Card className={classes.tutoRoot}>
                      <CardActionArea>
                        <CardMedia
                          src={airtable} component="img" alt='screenshot of search threads app' className={classes.tutoMedia}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Get Twitter threads to Airtable
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            This tutorial teaches you how to use Threads API to get Twitter Threads from your favorite Tweeps to your Airtable base without coding
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary" onClick={this.openNotionDoc}>
                          Learn More
                        </Button>
                      </CardActions>
                    </Card>
                </Container>
            </div>
            <Footer/>
        </div>
      );
  }
}

export default withStyles(styles, { withTheme: true })(App);
