import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Button from '@material-ui/core/Button';
import logo from "../assets/logo.png";
import {withStyles} from '@material-ui/core/styles';
import { MixPanel } from '../components/MixPanel';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    title: {
        color: 'white',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
        marginRight: theme.spacing(2)
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      inputRoot: {
        color: 'inherit',
        width: '100%',
        height: 44
      },
    option: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 15,
      },
    shortCode: {
        fontSize: "small",
        backgroundColor: "#20b2aa",
        color: "#fff",
        padding: "4px",
        borderRadius: "2px"
    },
    thingName: {
        fontSize: '80%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    bar: {
        backgroundColor: theme.palette.primary.main
    },
    rightMenu: {
        marginLeft: 'auto',
    },
    helpIcon: {
        color: '#ddd',
        paddingTop: '0.1em',
        cursor: 'pointer',
        '&:hover': {
            color: 'white'
        }
    },
    instruction:{
        color: '#ddd',
        maxWidth: 500,
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        },
    },
    logo: {
        maxHeight: 80,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
        },
    },
    accountIconClass: {
        marginLeft: 'auto',
        textDecoration: 'none',
        color: 'white',
    },
    blogLink:{
        color: 'white',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline'
        },
        marginRight: theme.spacing(2)
    }
});

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            inputValue: ''
        };
    }

     // handleSignIn = () => {
     //     window.open(apiBaseUrl + "/auth/twitter", "_self");
     // }
     goToRapidAPI = () => {
         MixPanel.track('Click RapidAPI')
         window.open("https://rapidapi.com/chaos87/api/threads/", "_self", {location: "header"});
     }

    render () {
        const { classes } = this.props;
        return(
            <div className={classes.grow}>
              <AppBar position="sticky" className={classes.bar}>
                  <Toolbar className={classes.toolbar}>
                         <img
                             src={logo}
                             alt="ThreadsAPI"
                             className={classes.logo}
                         />
                    <div className={classes.rightMenu}>
                        <Button
                          type="submit"
                          variant="outlined"
                          size="large"
                          color="default"
                          onClick={this.goToRapidAPI}
                        >
                          Get Started
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
          </div>
        )
    };
}

export default withStyles(styles, { withTheme: true })(Header);
